import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getDemandPartners } from 'app/entities/demand-partner/demand-partner.reducer';
import { ImpType } from 'app/shared/model/enumerations/imp-type.model';
import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { EndpointDatacenterLocation } from 'app/shared/model/enumerations/endpoint-datacenter-location.model';
import { AuctionType } from 'app/shared/model/enumerations/auction-type.model';
import { createEntity, getEntity, reset, updateEntity } from './demand.reducer';

export const DemandUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demandPartners = useAppSelector(state => state.demandPartner.entities);
  const demandEntity = useAppSelector(state => state.demand.entity);
  const loading = useAppSelector(state => state.demand.loading);
  const updating = useAppSelector(state => state.demand.updating);
  const updateSuccess = useAppSelector(state => state.demand.updateSuccess);
  const impTypeValues = Object.keys(ImpType);
  const dailyBudgetPacingValues = Object.keys(DailyBudgetPacing);
  const endpointDatacenterLocationValues = Object.keys(EndpointDatacenterLocation);
  const auctionTypeValues = Object.keys(AuctionType);

  const handleClose = () => {
    navigate(`/demand${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDemandPartners({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...demandEntity,
      ...values,
      demandPartner: demandPartners.find(it => it.id.toString() === values.demandPartner?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          impType: 'ALL',
          dailyBudgetPacing: 'AS_SOON_AS_POSSIBLE',
          endpointDatacenterLocation: 'US_EAST_OHIO',
          auctionType: 'First_Price',
          ...demandEntity,
          demandPartner: demandEntity?.demandPartner?.id,
        };
  return <div></div>;
};
export default DemandUpdate;
