import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getSupplyPartners } from 'app/entities/supply-partner/supply-partner.reducer';
import { getEntities as getSupplyToDemandFilters } from 'app/entities/supply-to-demand-filter/supply-to-demand-filter.reducer';
import { Datacenter } from 'app/shared/model/enumerations/datacenter.model';
import { createEntity, getEntity, reset, updateEntity } from './supply.reducer';

export const SupplyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const supplyPartners = useAppSelector(state => state.supplyPartner.entities);
  const supplyToDemandFilters = useAppSelector(state => state.supplyToDemandFilter.entities);
  const supplyEntity = useAppSelector(state => state.supply.entity);
  const loading = useAppSelector(state => state.supply.loading);
  const updating = useAppSelector(state => state.supply.updating);
  const updateSuccess = useAppSelector(state => state.supply.updateSuccess);
  const datacenterValues = Object.keys(Datacenter);

  const handleClose = () => {
    navigate(`/supply${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getSupplyPartners({}));
    dispatch(getSupplyToDemandFilters({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...supplyEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user?.toString()),
      supplyPartner: supplyPartners.find(it => it.id.toString() === values.supplyPartner?.toString()),
      stodfs: mapIdList(values.stodfs),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          datacenter: 'US_East',
          ...supplyEntity,
          user: supplyEntity?.user?.id,
          supplyPartner: supplyEntity?.supplyPartner?.id,
          stodfs: supplyEntity?.stodfs?.map(e => e.id.toString()),
        };
  return <div></div>;
};

export default SupplyUpdate;
