import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { DailyBudgetPacing } from 'app/shared/model/enumerations/daily-budget-pacing.model';
import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';
import { createEntity, getEntity, reset, updateEntity } from './demand-partner.reducer';

export const DemandPartnerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demandPartnerEntity = useAppSelector(state => state.demandPartner.entity);
  const loading = useAppSelector(state => state.demandPartner.loading);
  const updating = useAppSelector(state => state.demandPartner.updating);
  const updateSuccess = useAppSelector(state => state.demandPartner.updateSuccess);
  const dailyBudgetPacingValues = Object.keys(DailyBudgetPacing);
  const impressionTrackingMethodValues = Object.keys(ImpressionTrackingMethod);

  const handleClose = () => {
    navigate(`/demand-partner${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.dailyBudget !== undefined && typeof values.dailyBudget !== 'number') {
      values.dailyBudget = Number(values.dailyBudget);
    }
    if (values.totalBudget !== undefined && typeof values.totalBudget !== 'number') {
      values.totalBudget = Number(values.totalBudget);
    }

    const entity = {
      ...demandPartnerEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          dailyBudgetPacing: 'AS_SOON_AS_POSSIBLE',
          impressionTrackingMethod: 'BURL',
          ...demandPartnerEntity,
        };
  return <div></div>;
};

export default DemandPartnerUpdate;
