import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ImpressionTrackingMethod } from 'app/shared/model/enumerations/impression-tracking-method.model';
import { createEntity, getEntity, reset, updateEntity } from './supply-partner.reducer';

export const SupplyPartnerUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const supplyPartnerEntity = useAppSelector(state => state.supplyPartner.entity);
  const loading = useAppSelector(state => state.supplyPartner.loading);
  const updating = useAppSelector(state => state.supplyPartner.updating);
  const updateSuccess = useAppSelector(state => state.supplyPartner.updateSuccess);
  const impressionTrackingMethodValues = Object.keys(ImpressionTrackingMethod);

  const handleClose = () => {
    navigate(`/supply-partner${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...supplyPartnerEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          impressionTrackingMethod: 'BURL',
          ...supplyPartnerEntity,
        };
  return <div></div>;
};

export default SupplyPartnerUpdate;
