import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getDemands } from 'app/entities/demand/demand.reducer';
import { getEntities as getSupplies } from 'app/entities/supply/supply.reducer';
import { createEntity, getEntity, reset, updateEntity } from './supply-to-demand-filter.reducer';

export const SupplyToDemandFilterUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const demands = useAppSelector(state => state.demand.entities);
  const supplies = useAppSelector(state => state.supply.entities);
  const supplyToDemandFilterEntity = useAppSelector(state => state.supplyToDemandFilter.entity);
  const loading = useAppSelector(state => state.supplyToDemandFilter.loading);
  const updating = useAppSelector(state => state.supplyToDemandFilter.updating);
  const updateSuccess = useAppSelector(state => state.supplyToDemandFilter.updateSuccess);

  const handleClose = () => {
    navigate(`/supply-to-demand-filter${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDemands({}));
    dispatch(getSupplies({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.margin !== undefined && typeof values.margin !== 'number') {
      values.margin = Number(values.margin);
    }
    if (values.qps !== undefined && typeof values.qps !== 'number') {
      values.qps = Number(values.qps);
    }

    const entity = {
      ...supplyToDemandFilterEntity,
      ...values,
      demand: demands.find(it => it.id.toString() === values.demand?.toString()),
      supplies: mapIdList(values.supplies),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...supplyToDemandFilterEntity,
          demand: supplyToDemandFilterEntity?.demand?.id,
          supplies: supplyToDemandFilterEntity?.supplies?.map(e => e.id.toString()),
        };
  return <div></div>;
};

export default SupplyToDemandFilterUpdate;
